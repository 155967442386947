.slick-slider {
  position: relative;
  display: grid;
  align-items: center;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  grid-column: 1;
  position: relative;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-dots {
  display: flex !important;
  justify-content: end;
}
@media (max-width: 992px) {
  .slick-dots {
    margin: 0 20px;
  }
}
@media (max-width: 375px) {
  .slick-dots .slick-active button:before {
    width: 60px !important;
  }
}
@media (max-width: 375px) {
  .slick-dots li button:before {
    width: 60px !important;
  }
}
@media (max-width: 375px) {
  .slick-dots li {
    width: 60px !important;
  }
}

// dots styles
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  border-radius: 5px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 90px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  display: block;
  content: '"';
  background-color: #aaabb8;
  height: 10px;
  width: 90px;
  position: absolute;
  border-radius: 5px;
}
.slick-dots .slick-active button:before {
  display: block;
  content: '"';
  background-color: #2e9cca;
  height: 10px;
  width: 90px;
  position: absolute;
  border-radius: 5px;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 400px;
  min-height: 1px;
  display: flex;
  justify-content: center;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.slide img {
  width: 150px;
  margin: 0 auto;
}

.slideNoneDisplay {
  transform: scale(0.6);
  transition: transform 500ms;
  opacity: 0.5;
  transition: opacity 500ms;
}

.slideEnd {
  transform: scale(0.7);
  transition: transform 500ms;
}

.slide {
  transform: scale(0.85);
  transition: transform 500ms;
  justify-content: center;
}

.activeSlide {
  transform: scale(0.97);
  transition: transform 500ms;
}

.arrow {
  background-color: none;
  cursor: pointer;
}

.arrow svg {
  font-size: 50px;
  color: #57ab27;
  transition: color 500ms;
}

.arrow svg:hover {
  color: #57ab27;
}

.next {
  grid-column: 3;
}

.prev {
  grid-column: 1;
}
